import store from '@/store'
import developmentStoreModule from '@/pagsmile/developmentStoreModule'

export default function developmentJs() {
  const DEVELOPMENT_STORE_MODULE_NAME = 'development'

  // Register module
  if (!store.hasModule(DEVELOPMENT_STORE_MODULE_NAME)) store.registerModule(DEVELOPMENT_STORE_MODULE_NAME, developmentStoreModule)

  const fetchMerchantsFilter = (success, fail) => {
    store
      .dispatch('development/fetchMerchantsFilter')
      .then(response => {
        success(response.data)
      })
      .catch(() => {
        fail('error')
      })
  }

  const importV1Merchant = (params, success, fail) => {
    store
      .dispatch('development/importV1Merchant', params)
      .then(response => {
        success(response.data)
      })
      .catch(() => {
        fail('error')
      })
  }

  const importMerchantData = (params, success, fail) => {
    store
      .dispatch('development/importMerchantData', params)
      .then(response => {
        success(response.data)
      })
      .catch(() => {
        fail('error')
      })
  }

  const reimportMerchantApps = (params, success, fail) => {
    store
      .dispatch('development/reimportMerchantApps', params)
      .then(response => {
        success(response.data)
      })
      .catch(() => {
        fail('error')
      })
  }

  const syncAppKeys = (success, fail) => {
    store
      .dispatch('development/syncAppKeys')
      .then(response => {
        success(response.data)
      })
      .catch(() => {
        fail('error')
      })
  }

  const syncUsers = (success, fail) => {
    store
      .dispatch('development/syncUsers')
      .then(response => {
        success(response.data)
      })
      .catch(() => {
        fail('error')
      })
  }

  const recheckPixByDate = (params, success, fail) => {
    store
      .dispatch('development/recheckPixByDate', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const recheckPicPayByDate = (params, success, fail) => {
    store
      .dispatch('development/recheckPicPayByDate', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const recheckBoletoByDate = (params, success, fail) => {
    store
      .dispatch('development/recheckBoletoByDate', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const recheckPixByOrder = (params, success, fail) => {
    store
      .dispatch('development/recheckPixByOrder', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const recheckMachByOrder = (params, success, fail) => {
    store
      .dispatch('development/recheckMachByOrder', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const recheckPayPalByOrder = (params, success, fail) => {
    store
      .dispatch('development/recheckPayPalByOrder', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const recheckPicPayByOrder = (params, success, fail) => {
    store
      .dispatch('development/recheckPicPayByOrder', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const recheckLevpayByOrder = (params, success, fail) => {
    store
      .dispatch('development/recheckLevpayByOrder', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const recheckLostOrder = (params, success, fail) => {
    store
      .dispatch('development/recheckLostOrder', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const recheckBankBill = (params, success, fail) => {
    store
      .dispatch('development/recheckBankBill', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const updateOrderInfo = (params, success, fail) => {
    store
      .dispatch('development/updateOrderInfo', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const resettlement = (params, success, fail) => {
    store
      .dispatch('development/resettlement', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const updateStatus = (params, success, fail) => {
    store
      .dispatch('development/updateStatus', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const resendEmail = (params, success, fail) => {
    store
      .dispatch('development/resendEmail', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const cancelOrder = (params, success, fail) => {
    store
      .dispatch('development/cancelOrder', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const cancelAndDelete = (params, success, fail) => {
    store
      .dispatch('development/cancelAndDelete', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const regenerateMerchantReport = (params, success, fail) => {
    store
      .dispatch('development/regenerateMerchantReport', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const regenerateTrendReport = (params, success, fail) => {
    store
      .dispatch('development/regenerateTrendReport', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const regenerateMerchantSettlement = (params, success, fail) => {
    store
      .dispatch('development/regenerateMerchantSettlement', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const regenerateHourlyReportByApp = (params, success, fail) => {
    store
      .dispatch('development/regenerateHourlyReportByApp', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const executeBatchFill = (params, success, fail) => {
    store
      .dispatch('development/executeBatchFill', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const executeBatchCallback = (params, success, fail) => {
    store
      .dispatch('development/executeBatchCallback', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const updatePaymentByOrder = (params, success, fail) => {
    store
      .dispatch('development/updatePaymentByOrder', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const exportLuxpagSettlement = (params, success, fail) => {
    store
      .dispatch('development/exportLuxpagSettlement', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const upload = (params, success, fail) => {
    store
      .dispatch('development/upload', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  return {
    fetchMerchantsFilter,
    importV1Merchant,
    importMerchantData,
    reimportMerchantApps,
    syncAppKeys,
    syncUsers,
    recheckPixByDate,
    recheckBoletoByDate,
    recheckPicPayByDate,
    recheckPixByOrder,
    recheckPicPayByOrder,
    recheckMachByOrder,
    recheckPayPalByOrder,
    recheckLevpayByOrder,
    recheckLostOrder,
    recheckBankBill,
    updateOrderInfo,
    resettlement,
    updateStatus,
    resendEmail,
    cancelOrder,
    cancelAndDelete,
    regenerateMerchantReport,
    regenerateMerchantSettlement,
    regenerateHourlyReportByApp,
    executeBatchFill,
    executeBatchCallback,
    exportLuxpagSettlement,
    updatePaymentByOrder,
    upload,
    regenerateTrendReport,
  }
}
