import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 获取订单数据
    fetchMerchantsFilter() {
      return new Promise((resolve, reject) => {
        axios.post('/v1/merchant/list')
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    importV1Merchant(ctx, merchantNo) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/merchant/import?merchant_no=${merchantNo}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    importMerchantData(ctx, merchantNo) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/merchant/import-data?merchant_no=${merchantNo}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    reimportMerchantApps(ctx, merchantNo) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/merchant/reimport-apps?merchant_no=${merchantNo}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    syncAppKeys() {
      return new Promise((resolve, reject) => {
        axios.post('/v1/merchant/sync-keys')
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    syncUsers() {
      return new Promise((resolve, reject) => {
        axios.post('/v1/merchant/sync-users')
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    recheckPixByDate(ctx, checkDate) {
      return new Promise((resolve, reject) => {
        axios.post(`/backdoor/recheck/pix?check_date=${checkDate}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    recheckPicPayByDate(ctx, checkDate) {
      return new Promise((resolve, reject) => {
        axios.post(`/backdoor/recheck/picpay?check_date=${checkDate}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    recheckBoletoByDate(ctx, checkDate) {
      return new Promise((resolve, reject) => {
        axios.post(`/backdoor/recheck/boleto?check_date=${checkDate}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    recheckPixByOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/backdoor/recheck/pix', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    recheckMachByOrder(ctx, trades) {
      return new Promise((resolve, reject) => {
        axios.post('/backdoor/recheck/mach', trades)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    recheckPayPalByOrder(ctx, trades) {
      return new Promise((resolve, reject) => {
        axios.post('/backdoor/recheck/paypal', trades)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    recheckPicPayByOrder(ctx, trades) {
      return new Promise((resolve, reject) => {
        axios.post('/backdoor/recheck/picpay', trades)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    recheckLevpayByOrder(ctx, trades) {
      return new Promise((resolve, reject) => {
        axios.post('/backdoor/recheck/levpay', trades)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    recheckLostOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/backdoor/recheck/lost?trade_no=${params.trade_no}`, params.request_body, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    recheckBankBill(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/backdoor/recheck/bank-bill?bill_type=${params.bankBillType}&date=${params.checkDate}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    updateOrderInfo(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/backdoor/order/update', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    resettlement(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/backdoor/order/settle?password=${params.password}`, params.data)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    updateStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/backdoor/order/update-wrong-notify-orders?password=${params.password}&trade_status=${params.tradeStatus}`, params.data)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    resendEmail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/backdoor/order/resend-chargeback-email', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    cancelOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/backdoor/recheck/cancel', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    cancelAndDelete(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/backdoor/order/deal-safetypay-orders', params)
        axios.post(`/backdoor/order/deal-safetypay-orders?password=${params.password}&merchant_no=${params.merchantNo}`, params.data)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    regenerateMerchantReport(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/backdoor/report/merchant-daily?password=${params.password}&merchant_no=${params.reportMerchantNo}&date=${params.checkDate}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    regenerateTrendReport(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/backdoor/report/trend?password=${params.password}&start=${params.start}&end=${params.end}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    regenerateMerchantSettlement(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/backdoor/report/merchant-settlement?password=${params.password}&merchant_no=${params.merchantNo}&start=${params.start}&end=${params.end}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    regenerateHourlyReportByApp(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/backdoor/report/app-hourly?password=${params.password}&app_id=${params.app_id}&start=${params.start}&end=${params.end}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    executeBatchFill(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/backdoor/order/batch-fill-orders?password=${params.password}&trade_status=${params.fillOrderStatus}`, params.data)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    executeBatchCallback(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/backdoor/order/batch-callback?password=${params.password}&enqueue=${params.enqueue}`, params.data)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    updatePaymentByOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/backdoor/order/update-payment-by-order', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    exportLuxpagSettlement(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/settlement/export-luxpag-settlement?start_time=${params.start_time}&end_time=${params.end_time}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // upload files
    upload(ctx, params) {
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      return new Promise((resolve, reject) => {
        axios.post('/sanction/upload', params, header)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // end actions
  },
}
